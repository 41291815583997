<template>
    <base-index
        title="Report Drivers"
        :entities="entities"
        :columns="columns"
        @onPageChanged="onPageChanged"
        :config="config">

        <template #controls>
            <base-button
                @click="download"
                size="sm"
                type="neutral"><i class="fa fa-download"></i>Download</base-button>
        </template>

        <template #filters>
            <b-col sm="3" v-if="$store.getters['auth/isSuper']">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Branch <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getBranches"
                                placeholder="Pick Branch"
                                v-model="filter.branch_id">

                                <el-option v-for="option in branches"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Operation <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Pick Operation"
                                v-model="filter.operation_id"
                            >
                                <el-option v-for="option in operations"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="`${ option.name } (${ option.branch.name })`"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Drivers <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getReports"
                                :remote-method="getDrivers"
                                placeholder="Search Customer By Name Or Phone"
                                v-model="filter.driver_id"
                            >
                                <el-option v-for="option in drivers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Status <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Status"
                                v-model="filter.driver_has_paid"
                            >
                                <el-option v-for="option in hasPaidOptions"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="`${ option.value }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

        <template #summary>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Cash ($)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Cash (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">ABA ($)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceAbaUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">ABA (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceAbaRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

        <template #cell_price_usd="{ entity }">
            {{ entity.paid_to == 'chatone-cash' ? (entity.actual_receive_payment_usd || 0) : 0 }}
        </template>

        <template #cell_price_riel="{ entity }">
            {{ entity.paid_to == 'chatone-cash'  ? (entity.actual_receive_payment_riel || 0) : 0 }}
        </template>

        <template #cell_aba_usd="{ entity }">
            {{ entity.paid_to == 'chatone-aba' ? (entity.actual_receive_payment_usd || 0) : 0 }}
        </template>

        <template #cell_aba_riel="{ entity }">
            {{ entity.paid_to == 'chatone-aba'  ? (entity.actual_receive_payment_riel || 0) : 0 }}
        </template>

        <template #cell_driver_has_paid="{ entity }">
            <badge :type="entity.driver_has_paid ? 'success' : 'danger'">{{ entity.driver_has_paid ? 'Paid' : 'Not Paid' }}</badge>
        </template>

        <template #actions="{ entity }">
            <el-tooltip placement="top" :content="`pay`">
                <base-button @click="showModal(entity)" size="sm" type="info"><i class="fa fa-coins"></i></base-button>
            </el-tooltip>
        </template>

        <template #others>
            <modal-input-data @pay="pay" ref="modalInputData"></modal-input-data>
        </template>
    </base-index>
</template>
<script>
import BaseIndex from '@components/Custom/BaseIndex';
import "flatpickr/dist/flatpickr.css";
import {
    Select,
    Option,
} from 'element-ui'
import store from './store';
export default {
    data() {
        return  {
            filter: {
                operation_id: '',
                customer_id: '',
                branch_id: '',
                page: 1,
                driver_has_paid: null
            },
            columns: [
                {
                    label: 'Code',
                    key: 'driver.code'
                },
                {
                    label: 'Destination',
                    key: 'destination.name'
                },
                {
                    label: 'Price($)',
                    key: 'price_usd',
                    formatAs: 'currency'
                },
                {
                    label: 'Price(៛)',
                    key: 'price_riel',
                    formatAs: 'currency'
                },
                {
                    label: 'ABA($)',
                    key: 'aba_usd',
                    formatAs: 'currency'
                },
                {
                    label: 'ABA(៛)',
                    key: 'aba_riel',
                    formatAs: 'currency'
                },
                {
                    label: 'Remark',
                    key: 'driver_paid_remark'
                },
                {
                    label: 'Paid',
                    key: 'driver_has_paid'
                },
            ],
            config: {
                showAddNew: false,
                hasBasicActions: false
            },
            selectedEntity: null,
            hasPaidOptions: [
                {
                    label: 'Paid',
                    value: true
                },
                {
                    label: 'Not Paid',
                    value: false
                }
            ],
        };
    },
    methods: {
        showModal(entity) {
            this.selectedEntity = entity;
            this.$refs.modalInputData.show({
                remark: entity.driver_paid_remark
            });
        },
        pay(meta) {
            store.dispatch('updateDriverHasPaid', {
                    entity: this.selectedEntity,
                    data: {
                        driver_has_paid: !this.selectedEntity.driver_has_paid,
                        driver_paid_remark: meta.remark 
                    }
                }).then(() => {
                    this.toastSuccess('Mark as completed');
                    this.getReports();
                    this.$refs.modalInputData.hide();
                });
        },
        download() {
            store.dispatch('download', this.filter);
        },
        getDrivers(search) {
            store.dispatch('getDrivers', {
                search
            });
        },
        getReports() {
            store.dispatch('index', this.filter);
            store.dispatch('getDriversSummaryTotal', this.filter);
        },
        getBranches() {
            store.dispatch('getBranches')
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getReports();
        }
    },
    mounted() {
        this.getReports();
        this.getBranches();
        store.dispatch('getOperations');
    },
    computed: {
        entities() {
            return store.getters['entities'];
        },
        total() {
            return store.getters['total'];
        },
        operations() {
            return store.getters['operations'].filter(operation => {
                return this.filter.branch_id ? operation.branch_id === this.filter.branch_id : true;
            });
        },
        branches() {
            return store.getters['branches'];
        },
        drivers() {
            return store.getters['drivers'];
        },
        totalPriceUsd() {
            return this.total.total_price_usd ? new Intl.NumberFormat().format(this.total.total_price_usd.toFixed(2)) : 0;
        },
        totalPriceRiel() {
            return this.total.total_price_riel ? new Intl.NumberFormat().format(this.total.total_price_riel.toFixed(2)) : 0;
        },
        totalPriceAbaUsd() {
            return this.total.total_aba_price_usd ? new Intl.NumberFormat().format(this.total.total_aba_price_usd.toFixed(2)) : 0;
        },
        totalPriceAbaRiel() {
            return this.total.total_aba_price_riel ? new Intl.NumberFormat().format(this.total.total_aba_price_riel.toFixed(2)) : 0;
        }
    },
    watch: {
        'filter.date': function(val) {
            if (val) {
                this.getReports();
            }
        }
    },
    components: {
        BaseIndex,
        [Select.name]: Select,
        [Option.name]: Option,
        ModalInputData: () => import('./ModalInputData')
    }
}
</script>
<style lang="scss" scope>
    .cls-form input {
        color: #000 !important;
        font-weight: 600;
    }
</style>